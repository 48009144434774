import Media from 'UI/components/Media';
import Svg from 'UI/components/Svg';
import ContentfulParser from 'components/BlogCommon/Article/ContentfulParser';
import styles from './styles.module.scss';

const SliderCard = ({
  person,
  text,
}) => (
  <article className={styles.card}>
    <div className={styles['card-body']}>
      {text && (
        <div className={styles['card-text']}>
          <Svg
            type="bookMarkQuote"
            className={styles.quote}
          />
          <ContentfulParser document={text} />
        </div>
      )}
      {person && (
        <div className={styles.person}>
          {person?.avatar && (
            <Media
              asset={person.avatar}
              className={styles.avatar}
            />
          )}
          <span>
            {person?.fullName && (
              <p className={styles.fullName}>{person.fullName}</p>
            )}
            {person?.position && (
              <p className={styles.position}>{person.position}</p>
            )}
          </span>
        </div>
      )}
    </div>
  </article>
);

export default SliderCard;
