import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionTitle from 'UI/components/SectionTitle';
import SliderCard from 'UI/components/SliderCard';
import SwiperNavButton from 'UI/components/SwiperNavButton';
import { selectIsMobile, selectIsSmallTablet } from 'redux/selectors/layout';
import { getDocumentFields, getFileUrl } from 'utils/helper';
import classNames from 'classnames';
import { sliderParams } from './helper';
import styles from './styles.module.scss';

const CardsSlider = ({
  section,
  type,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const isSmallTablet = useSelector(selectIsSmallTablet);
  const isMobile = useSelector(selectIsMobile);

  const onSwiperInit = (swiper) => {
    swiper.params.navigation.prevEl = prevRef.current;
    swiper.params.navigation.nextEl = nextRef.current;
    swiper.navigation.init();
    swiper.navigation.update();
  };

  const {
    title,
    description,
    contentModules,
  } = getDocumentFields(
    section,
    [
      'title',
      'description',
      'contentModules',
    ],
  );

  const cardList = useMemo(() => contentModules?.map((module) => {
    const {
      images,
      text,
      contentModules: personModule,
      imagesBundles,
    } = getDocumentFields(
      module,
      [
        'contentModules',
        'text',
        'images',
        'imagesBundles',
      ],
    );
    const person = getDocumentFields(
      personModule?.[0],
      ['avatar', 'fullName', 'position'],
    );

    const posterUrl = getFileUrl(imagesBundles?.[0]);

    return {
      media: images?.[0],
      text,
      person,
      posterUrl,
    };
  }), [contentModules]);

  return (
    <section className={classNames(styles.section, styles[type])}>
      <div className={styles['section-header']}>
        <SectionTitle
          title={title}
          description={description}
          className={styles['section-title']}
        />
        {!isSmallTablet && !isMobile && (
          <div className={styles.navigation}>
            <SwiperNavButton
              type="prev"
              className={styles['navigation-button']}
              buttonRef={prevRef}
            />
            <SwiperNavButton
              type="next"
              className={styles['navigation-button']}
              buttonRef={nextRef}
            />
          </div>
        )}
      </div>
      <Swiper
        {...sliderParams}
        onInit={onSwiperInit}
        // style={{ height: '100%' }}
      >
        {(cardList || []).map((card, i) => (
          <SwiperSlide
            key={`text-${i}`}
          >
            <SliderCard
              text={card.text}
              person={card.person}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default CardsSlider;
