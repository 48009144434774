import { Mousewheel, Navigation } from 'swiper';

export const sliderParams = {
  autoHeight: true,
  slidesPerView: 1.42,
  centeredSlides: true,
  loop: true,
  passiveListeners: true,
  speed: 800,
  spaceBetween: 32,
  mousewheel: {
    forceToAxis: true,
  },
  breakpoints: {
    375: {
      spaceBetween: 8,
      slidesPerView: 1.1,
    },
    768: {
      spaceBetween: 16,
    },
    1024: {
      spaceBetween: 32,
    },
    1440: {
      slidesPerView: 1.9,
    },
    2048: {
      slidesPerView: 2.5,
    },
    3000: {
      slidesPerView: 3,
    },
  },
  modules: [
    Mousewheel,
    Navigation,
  ],
};
